import { Button, Input, Link, Image } from "@nextui-org/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MessageInformations from "./Utils/MessageInformations";
import { useDisclosure } from "@nextui-org/react";
import { confirmAlert } from "react-confirm-alert";
import { MutatingDots } from "react-loader-spinner";
import { set } from "lodash";

function Login({logged, isLogged}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [accueil, setAccueil] = useState(require("../assets/accueilDefault.png"));
    const [logoClient, setLogoClient] = useState(require("../assets/EvoScreenLogo.jpg"));
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL_API + "/client/get_login_image", {
        withCredentials: true,
      }).then((response) => {
        setAccueil(response.data.logo_accueil);
        setLogoClient(response.data.logo_principal);
        setLoading(false);
      }).catch((error) => {
        setLogoClient(require("../assets/EvoScreenLogo.jpg"));
        setAccueil(require("../assets/accueilDefault.png"));
        setLoading(false);
      });
    }, []);
    
    const handleLogin = (e) => {
      e.preventDefault();
      if (username === "" || password === "") {
          return;
      }
        axios.post(process.env.REACT_APP_BASE_URL_API + "/users/login", {
            email: username,
            password: password,
        }, { withCredentials: true }).then((response) => {
                localStorage.clear();
                setTimeout(() => {
                  isLogged(true);
                }, 2000);
                navigate("/dashboard");
        }).catch((error) => {
          if (!error.response) {
            onOpen();
            setErrorMessage({
              content: "Vérifiez votre connexion internet.",
              size: "sm",
              title: "Erreur de connexion"
            });
            return;
          }
          if (error.response.status == 404) {
            onOpen();
            setErrorMessage({
              content: "L'utilisateur n'existe pas. Veuillez réessayer.",
              size: "sm",
              title: "Erreur de connexion"
            });
        }
        else if (error.response.status == 403) {
            onOpen();
            setErrorMessage({
              content: "Le nom d'utilisateur ou le mot de passe est incorrect. Veuillez réessayer.",
              size: "sm",
              title: "Erreur de connexion"
            });
        }
        else {
          onOpen();
            setErrorMessage({
              content: "Contactez les administrateur si cette erreur persiste.",
              size: "sm",
              title: "Erreur de connexion"
            });
        }
        });
    }


    if (logged === undefined || accueil === undefined || logoClient === undefined || loading) {
      return <div className='flex items-center justify-center h-screen'><MutatingDots
      visible={true}
      height="100"
      width="100"
      color="#0070f0"
      secondaryColor="#0070f0"
      radius="12.5"
      ariaLabel="mutating-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
      </div>
    }

      return (   
      <div className='flex flex-col items-center justify-center h-screen border-black lg:flex-row dark:bg-white'>

        <div className='absolute first-letter:items-start left-1 hidden object-cover w-2/4 lg:flex h-1/4 lg:w-[65%] lg:h-[100%]' style={{clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0 100%)'}}>
          <img
            src={accueil}
            className='object-cover w-full h-full' 
          />
        </div>

        <div className='flex flex-col items-center justify-center w-full h-full lg:items-end' >
          <form className='flex flex-col items-center justify-center flex-1 w-full lg:w-2/5' onSubmit={handleLogin}>

            <div className="flex items-center justify-center">
              <Image src={logoClient} alt="login" className='items-center justify-center w-44 lg:w-64'/>
            </div>

            <span className='flex m-2 text-2xl font-bold text-black'>Espace client</span>

            <div className='flex flex-col items-center justify-center w-full mt-4 space-y-4'>
              <Input
                autoFocus
                isRequired
                label="Nom d'utilisateur"
                variant="bordered"
                className='w-3/5'
                onValueChange={setUsername}
                ></Input>
                <Input
                isRequired
                onChange={(e) => setPassword(e.target.value)}
                label="Mot de passe"
                placeholder=""
                type='password'
                variant="bordered"
                className='w-3/5'
              />
            </div>

            <div className='flex flex-row items-end justify-end w-2/3 mb-2'>
              <Link className='flex m-2 text-xs' href="/reset-password">Mot de passe oublié?</Link>
            </div>

            <div className="flex flex-col items-center justify-center w-full">
              <Button type="submit" color="primary" className="justify-center w-3/5 text-white shadow-md shadow-primary-content">Connexion</Button>
              <span className='flex m-2 text-sm text-black'>Une solution de <Link className="ml-1 text-sm" href="https://evoscreen.ch" target="_blank">Evo One SA</Link></span>
            </div>

          </form>

          <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={errorMessage}/>
          
        </div>
      </div>
      )
}

export default Login;